import styled from 'styled-components';

import { Device } from '../utils/media-queries-utils';

export const HeroContainer = styled.div`
  padding: 50px 110px 60px;

  @media ${Device.laptopL} {
    padding: 50px 50px 60px;
  }

  @media ${Device.laptop} {
    padding: 50px 30px 60px;
  }

  @media ${Device.mobileS} {
    padding: 50px 10px 60px;
  }
`;
