import Img from 'gatsby-image';
import styled from 'styled-components';

export const Avatar = styled(Img)`
  border-radius: 9999px;
  height: 100px;
  width: 100px;
  z-index: 1;

  & + & {
    z-index: 2;
    margin-left: -20px;
  }
`;
