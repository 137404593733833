import { always, ifElse, propEq } from 'ramda';
import styled from 'styled-components';

import { Device } from '../utils/media-queries-utils';
import { H3, H4, Typography } from './Typography';

export interface BlockquoteProps {
  theme: 'light' | 'dark';
}

const getBackgroundByTheme = ifElse(propEq('theme', 'dark'), always('#04093D'), always('#F2F3F5'));

const getHeadlineColorByTheme = ifElse(
  propEq('theme', 'dark'),
  always('#8C91BD'),
  always('#051392'),
);

const getTextColorByTheme = ifElse(propEq('theme', 'dark'), always('#F2F2F2'), always('#000'));

export const Blockquote = styled.div<BlockquoteProps>`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px;
  background-color: ${getBackgroundByTheme};
  min-height: 480px;

  ${H4} {
    color: ${getHeadlineColorByTheme};
    text-align: center;
  }

  ${H3}, ${Typography} {
    line-height: 29px;
    font-size: 24px;
    color: ${getTextColorByTheme};
  }

  @media ${Device.tablet} {
    padding: 70px 35px;
  }
`;

Blockquote.defaultProps = {
  theme: 'light',
};
