import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { useMediaLayout } from 'use-media';

import { ViewportSize } from '../utils/media-queries-utils';
import { Avatar } from './Avatar';
import { Blockquote } from './Blockquote';
import { H3, H4, Typography } from './Typography';

export function Quotes(): JSX.Element {
  const staticData = useStaticQuery(graphql`
    query {
      quote1: file(relativePath: { eq: "quote-1.jpg" }) {
        childImageSharp {
          fixed(width: 100, height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      quote2: file(relativePath: { eq: "quote-2.jpg" }) {
        childImageSharp {
          fixed(width: 100, height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const isWide = useMediaLayout({ minWidth: ViewportSize.laptop });

  return (
    <Blockquote className="justify-start">
      <H4 className="mb-6 lg:mb-20">What People are Saying</H4>
      <div
        className="flex flex-col lg:flex-row mx-auto max-w-3/4"
        style={{ maxWidth: isWide ? '75%' : '100%' }}>
        <div
          className="flex flex-row relative justify-center lg:justify-start mb-6 lg:mb-0"
          style={{ minWidth: 200 }}>
          {/* @ts-ignore */}
          <Avatar alt="client" fixed={staticData.quote1.childImageSharp.fixed} />
          {/* @ts-ignore */}
          <Avatar alt="client" className="mr-6" fixed={staticData.quote2.childImageSharp.fixed} />
        </div>
        <div className="flex flex-col">
          <H3 className="mb-10 text-left">
            &ldquo;Our lawfirm uses HyperCite<sup>&reg;</sup> on all of our documentation to save
            time so that we can focus on more important things like winning our cases!&rdquo;
          </H3>
          <Typography className="text-left" style={{ fontSize: 15 }}>
            Larry H. Parker | Larry H. Parker Law Firm
          </Typography>
        </div>
      </div>
    </Blockquote>
  );
}
