import React from 'react';
import styled from 'styled-components';

import { Device } from '../utils/media-queries-utils';

const Page = styled.div`
  min-height: 600px;
  width: 404px;
  padding: 50px 35px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media ${Device.mobileL} {
    padding: 30px 20px;
  }
`;

const Header = styled.h1`
  font-family: 'Century Schoolbook', serif;
  font-size: 13px;
  font-weight: bold;
  text-indent: 0px;
  text-align: center;
`;

const Paragraph = styled.p`
  font-family: 'Century Schoolbook', serif;
  font-size: 13px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  text-indent: 30px;
  line-height: 20px;
  text-align: justify;

  i {
    font-size: 13px;
    font-family: 'Century Schoolbook Italic', serif;
    font-style: italic;
  }

  b {
    font-size: 13px;
    font-family: 'Century Schoolbook Bold', serif;
    font-weight: bold;
  }

  a {
    font-size: 13px;
    text-decoration: underline;
    text-decoration-color: blue;
  }

  @media ${Device.mobileL} {
    &,
    & h1,
    & i,
    & a,
    & b {
      font-size: 10px;
    }
  }
`;

export function SampleCase(): JSX.Element {
  return (
    <Page className="relative mb-4 right-0 top-0 lg:absolute lg:mb-0 lg:right-8 lg:top-8">
      <Header>STATEMENT OF THE CASE</Header>

      <Paragraph>
        Appellant Clinton Quin was convicted of two counts of aggravated assault for an incident in
        which he commanded his four dogs to attack a bicyclist.{' '}
        <a href="https://www.appeals2.az.gov/APL2NewDocs1/COA/813/3457353.TIF">(R.O.A. 59.)</a> The
        evidence at trial established the following facts, viewed in the light most favorable to
        support his convictions. (See State v. Tamplin, 195 Ariz. 246, 246, ¶ 2 (App. 1999)
      </Paragraph>

      <Paragraph>
        On May 27, 2017, Andrew Rudie was riding his bicycle on a dirt path in Tucson when he
        encountered Quin and his four dogs. (Id.;{' '}
        <a href="https://www.appeals2.az.gov/APL2NewDocs1/COA/814/3459377.pdf">R.T. 10/2/18</a>, at
        28–31, 56.) The dogs were not leashed and Rudie thought that Quin did not have them under
        control. (
        <a href="https://www.appeals2.az.gov/APL2NewDocs1/COA/814/3459377.pdf">
          COA-<i>Id</i>.
        </a>{' '}
        at 32–33.) Quin had his hand up, so Rudie stopped approximately 30 feet away. (
        <a href="https://www.appeals2.az.gov/APL2NewDocs1/COA/814/3459377.pdf">
          COA-<i>Id</i>.
        </a>{' '}
        at 32.)
      </Paragraph>

      <Paragraph>
        Rudie raised his hand, ready to strike Quin, but did not hit him. (
        <a href="https://www.appeals2.az.gov/APL2NewDocs1/COA/814/3459377.pdf">R.T. 10/2/18</a> at
        34.) Rudie decided to leave the area, and asked Quin to keep his dogs away from him. (
        <a href="https://www.appeals2.az.gov/APL2NewDocs1/COA/814/3459377.pdf">
          COA-<i>Id</i>.
        </a>{' '}
        at 35–36.) Instead, Quin yelled “Get him.” (
        <a href="https://www.appeals2.az.gov/APL2NewDocs1/COA/814/3459377.pdf">
          COA-<i>Id</i>.
        </a>{' '}
        at 36.) All four dogs then bit Rudie. (
        <a href="https://www.appeals2.az.gov/APL2NewDocs1/COA/814/3459377.pdf">
          COA-<i>Id</i>;
        </a>{' '}
        see also, <a href="https://www.appeals2.az.gov/APL2NewDocs1/COA/834/3499407.pdf">O.B.</a>{' '}
        ep. 10-11, 12.)
      </Paragraph>

      <Paragraph>
        After a 2-day trial, a jury found Quin guilty of aggravated assault, (
        <a href="https://www.appeals2.az.gov/APL2NewDocs1/COA/814/3459376.pdf">R.T. 10/3/18</a>{' '}
        p.m., at 49–50;{' '}
        <a href="https://www.appeals2.az.gov/APL2NewDocs1/COA/813/3457348.TIF">R.O.A. 54</a>,{' '}
        <a href="https://www.appeals2.az.gov/APL2NewDocs1/COA/813/3457349.TIF">55</a>,{' '}
        <a href="https://www.appeals2.az.gov/APL2NewDocs1/COA/813/3457351.TIF">57</a>,{' '}
        <a href="https://www.appeals2.az.gov/APL2NewDocs1/COA/813/3457353.TIF">59.</a>) After
        finding that Quin had two prior felony convictions, the trial court sentenced him as a
        repetitive offender. (
        <a href="https://www.appeals2.az.gov/APL2NewDocs1/COA/816/3464087.pdf">R.T. 11/20/18,</a> at
        7; <a href="https://www.appeals2.az.gov/APL2NewDocs1/COA/813/3457369.TIF">R.O.A. 75.</a>)
        Quin filed a timely notice of appeal. (
        <a href="https://www.appeals2.az.gov/APL2NewDocs1/COA/813/3457371.TIF">R.O.A. 77</a>,{' '}
        <a href="https://www.appeals2.az.gov/APL2NewDocs1/COA/813/3457372.TIF">78.</a>) This Court
        has jurisdiction. Article VI, Section 9, and Arizona Revised Statutes §§ 12–120.21(A)(1),
        13–4031, and –4033(A)
      </Paragraph>
    </Page>
  );
}
