import React from 'react';
import styled from 'styled-components';
import BaseWriter, { TypewriterClass } from 'typewriter-effect';

const Container = styled.div`
  background-color: white;
  min-height: 440px;
  width: 590px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;

  font-family: 'Norse', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 42px;
  text-align: center;
`;

const WRITER_OPTIONS = {
  autoStart: true,
  loop: true,
};

export function Typewriter(): JSX.Element {
  function onInit(typewriter: TypewriterClass) {
    typewriter
      .pauseFor(2500)
      .typeString('Write citations in the manner the Court already requests.')
      .pauseFor(2500)
      .deleteAll()
      .typeString('Want to link Court of Appeals (Id.) cites? Write (COA-Id.) instead.')
      .pauseFor(2500)
      .deleteAll()
      .typeString('Keep using just (Id.) for everything else.')
      .pauseFor(2500)
      .deleteAll()
      .typeString('Happy hyperlinking!')
      .pauseFor(2500)
      .deleteAll()
      .start();
  }

  return (
    <Container>
      <BaseWriter onInit={onInit} options={WRITER_OPTIONS} />
    </Container>
  );
}
